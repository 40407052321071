"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function calc(scrollY, rect) {
    if (scrollY < rect.y)
        return 0;
    if (scrollY > rect.y + rect.height)
        return 1;
    return (scrollY - rect.y) / rect.height;
}
exports.default = calc;
