"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("./style.scss");
var HEIGHT = 0.15;
var default_1 = (function () {
    function default_1(_a) {
        var target = _a.target, element = _a.element;
        this.element = null;
        this.rect = new DOMRect();
        this.isOpen = false;
        if (target) {
            this.element = element;
            this.rect = target.getBoundingClientRect();
            document.addEventListener("scroll", this.onScrolling.bind(this), false);
            this.onScrolling();
        }
    }
    default_1.prototype.onScrolling = function () {
        var isHigher = window.scrollY > this.rect.height * HEIGHT;
        if (isHigher && this.isOpen)
            return;
        if (!isHigher && !this.isOpen)
            return;
        if (isHigher) {
            this.setIsOpen(true);
            return;
        }
        this.setIsOpen(false);
    };
    default_1.prototype.setIsOpen = function (open) {
        var _a, _b;
        this.isOpen = open;
        if (open) {
            (_a = this.element) === null || _a === void 0 ? void 0 : _a.classList.add("show");
        }
        else {
            (_b = this.element) === null || _b === void 0 ? void 0 : _b.classList.remove("show");
        }
    };
    return default_1;
}());
exports.default = default_1;
