"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("@/services/api");
require("./style.scss");
var default_1 = (function () {
    function default_1(el) {
        var _a, _b;
        this.element = el || undefined;
        if (!this.element)
            return;
        this.form = (el === null || el === void 0 ? void 0 : el.getElementsByTagName("form").item(0)) || undefined;
        this.mobileField = (_a = this.form) === null || _a === void 0 ? void 0 : _a.querySelector("[data-mobile-field]");
        (_b = this.form) === null || _b === void 0 ? void 0 : _b.addEventListener("submit", this.onFormSubmit.bind(this));
        this.mobileField.addEventListener("input", this.fieldChange.bind(this));
    }
    default_1.prototype.fieldChange = function (e) {
        var _a, _b, _c, _d, _e, _f;
        (_a = this.mobileField) === null || _a === void 0 ? void 0 : _a.setCustomValidity("");
        if (new RegExp(/^(([+]46)\s*(7)|07)[02369]\s*(\d{4})\s*(\d{3})$/).test(((_b = this.mobileField) === null || _b === void 0 ? void 0 : _b.value) || "") === false) {
            (_c = this.mobileField) === null || _c === void 0 ? void 0 : _c.setCustomValidity("Inget giltigt mobilnummer");
        }
        if (((_d = this.mobileField) === null || _d === void 0 ? void 0 : _d.value.length) === 0) {
            (_e = this.mobileField) === null || _e === void 0 ? void 0 : _e.setCustomValidity("Fyll i ditt mobilnummer");
        }
        (_f = this.mobileField) === null || _f === void 0 ? void 0 : _f.reportValidity();
    };
    default_1.prototype.onFormSubmit = function (e) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return __awaiter(this, void 0, void 0, function () {
            var mobileNumber, url, err_1;
            return __generator(this, function (_j) {
                switch (_j.label) {
                    case 0:
                        _j.trys.push([0, 2, , 3]);
                        e.preventDefault();
                        this.fieldChange();
                        if (!((_a = this.form) === null || _a === void 0 ? void 0 : _a.checkValidity())) {
                            return [2];
                        }
                        (_b = this.element) === null || _b === void 0 ? void 0 : _b.classList.add("sending");
                        mobileNumber = ((_c = this.mobileField) === null || _c === void 0 ? void 0 : _c.value) || "";
                        url = window.location.href.includes("?")
                            ? window.location.href + "&recipe"
                            : window.location.href + "?recipe";
                        return [4, (0, api_1.shareRecipe)({
                                mobile: mobileNumber.startsWith("+467", 0)
                                    ? mobileNumber
                                    : (_d = this.mobileField) === null || _d === void 0 ? void 0 : _d.value.replace(/^07/, "+467"),
                                link: url,
                            })];
                    case 1:
                        _j.sent();
                        (_e = this.element) === null || _e === void 0 ? void 0 : _e.classList.remove("sending");
                        (_f = this.element) === null || _f === void 0 ? void 0 : _f.classList.add("done");
                        return [3, 3];
                    case 2:
                        err_1 = _j.sent();
                        (_g = this.element) === null || _g === void 0 ? void 0 : _g.classList.remove("sending");
                        (_h = this.element) === null || _h === void 0 ? void 0 : _h.classList.add("error");
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    return default_1;
}());
exports.default = default_1;
